import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import Offcanvas from "react-bootstrap/Offcanvas"
import css from "./css/topMenu.module.css"
import logo from "../images/icon/logo.png"

import "../compoients/css/btn.css"
import { useEffect, useState, useContext } from "react"
import { AuthContext } from "../AuthContext"
import { useQuery } from "@apollo/client"
import { GET_MENUS } from "../graphql/Menu/MENU_GQL"
import { Paths } from "../utils/Paths"
import { useMediaQuery } from "react-responsive"
import { mainMenu } from "../data/topMenu"
import useDeviceQueries from "../hooks/useDeviceQueries"

const TopMenu = () => {
  const { isDesktop, isTablet, isMobile } = useDeviceQueries()

  // const { loading, error, data } = useQuery(GET_MENUS)
  const [sortedData, setStortedData] = useState([])

  const { state, dispatch } = useContext(AuthContext)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    if (state.loggedIn || localStorage.getItem("isLogin") === "true") {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [state, localStorage.getItem("isLogin")])

  // useEffect(() => {
  //   if (data) {
  //     setStortedData([...data.menus].sort((a, b) => a.order - b.order))
  //   }
  // }, [data])

  useEffect(() => {
    setStortedData([...mainMenu])
  }, [mainMenu])

  const expand = "lg"
  const menuList = (item, index) => {
    if (item.label === "貸款產品") {
      return (
        <NavDropdown
          title={item.label}
          id={`offcanvasNavbarDropdown-expand-${expand}`}
          key={index}
        >
          {sortedData?.[0].map((subitem, idx) => (
            <div key={idx}>
              {subitem.order !== 1 && <NavDropdown.Divider />}
              <NavDropdown.Item href={subitem.url}>
                {subitem.label}
              </NavDropdown.Item>
            </div>
          ))}
        </NavDropdown>
      )
    } else {
      return (
        <Nav.Link key={index} href={item.url}>
          {item.label}
        </Nav.Link>
      )
    }
  }

  // const container_style =
  //   pathname === "/" ? { position: "absolute", top: "0", zIndex: "99" } : {}

  return (
    <div className={css.container}>
      <Navbar
        key={false}
        bg=""
        expand={expand}
        fixed={isMobile ? "top" : ""}
        style={{ zIndex: "99999999" }}
      >
        <Container fluid="xxl">
          <Navbar.Brand href="/" style={{ marginRight: "0" }}>
            <img src={logo} alt="movingup" className={css.logo} />
          </Navbar.Brand>
          <div className={css.mobile_menu_container}>
            {(isMobile || isTablet) && (
              <Nav.Link href={Paths.userLogin} className="pe-4">
                <button className="menu-apply button_hover_motion2 floating-button">
                  <span>立即申請</span>
                </button>
              </Nav.Link>
            )}
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          </div>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                Offcanvas
              </Offcanvas.Title> */}
              <Navbar.Brand href="/">
                <img src={logo} alt="movingup" className={css.logo} />
              </Navbar.Brand>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav
                className={`justify-content-end align-items-center flex-grow-1 pe-3 ${css.text}`}
              >
                {sortedData?.map((item, index) => menuList(item, index))}

                <Nav.Link href={Paths.userLogin}>
                  <button className="menu-apply">立即申請</button>
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  )
}

export default TopMenu

import React, { useState, useEffect, useContext, useRef } from "react"
import { Box, Button, TextField, FormHelperText, Alert } from "@mui/material"
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth"
import { auth } from "../../firebase"
import { Spinner } from "gestalt"
import styles from "./phoneSMSVerification.module.css"

const PhoneSMSVerification = ({ onSuccess, setPhone }) => {
  const [isTesting, setIsTesting] = useState(false)
  const [formState, setFormState] = useState({
    phoneNumb: "",
    idCard: "",
    password: "",
    displayName: "",
    error: "",
    msg: "",
    showSpin: false,
    sms: "",
    phoneVerified: false,
    expandForm: false,
    showsendBtn: true,
    smserror: "",
    OTP: "",
    isSentCode: false,
    redo: false,
    isSuccess: false,
    showRecaptcha: true,
    disableSendCode: false,
    sendingSMS: false,
    sendingOTP: false,
  })

  const recaptchaContainerRef = useRef(null)

  const {
    phoneNumb,
    error,
    showSpin,
    phoneVerified,
    expandForm,
    showsendBtn,
    smserror,
    OTP,
    isSentCode,
    redo,
    isSuccess,
    showRecaptcha,
    disableSendCode,
    sendingSMS,
    sendingOTP,
  } = formState

  /******handle Recaptha */
  const generateRecaptha = () => {
    if (recaptchaContainerRef.current) {
      setFormState((prevState) => ({ ...prevState, showRecaptcha: true }))
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
        },
        auth
      )
    }
  }

  /****end handle Recaptha********* */

  /******handle Code ******/

  const sentCode = () => {
    setFormState((prevState) => ({
      ...prevState,
      smserror: "",
      expandForm: true,
      isSentCode: true,
    }))
  }

  const failsendCode = () => {
    setFormState((prevState) => ({
      ...prevState,
      isSentCode: false,
      redo: true,
      isSuccess: false,
      showsendBtn: false,
    }))
  }

  /***** handle Code*******/

  /*** verify status ***/
  const verified = () => {
    setFormState((prevState) => ({
      ...prevState,
      smserror: "",
      phoneVerified: true,
      isSuccess: true,
    }))
  }

  const notVerified = () => {
    setFormState((prevState) => ({
      ...prevState,
      phoneVerified: false,
      redo: true,
    }))
  }
  /***end verify status****/

  /****reset status ****/

  const restart = () => {
    setFormState({
      phoneNumb: "",
      idCard: "",
      password: "",
      displayName: "",
      error: "",
      msg: "",
      showSpin: false,
      sms: "",
      phoneVerified: false,
      expandForm: false,
      showsendBtn: true,
      smserror: "",
      OTP: "",
      isSentCode: false,
      redo: false,
      isSuccess: false,
      showRecaptcha: true,
    })
  }
  /****end reset status *****/

  /*** vaildation ***** */
  useEffect(() => {
    if (checkRepeatNumber() === false && checkPhoneFormat() === true) {
      setFormState((prevState) => ({ ...prevState, phoneVerified: true }))
    } else {
      setFormState((prevState) => ({ ...prevState, phoneVerified: false }))
    }
  }, [phoneVerified, phoneNumb])

  function checkRepeatNumber() {
    let flag = false
    if (
      phoneNumb.includes("11111") ||
      phoneNumb.includes("22222") ||
      phoneNumb.includes("33333") ||
      phoneNumb.includes("44444") ||
      phoneNumb.includes("55555") ||
      phoneNumb.includes("66666") ||
      phoneNumb.includes("77777") ||
      phoneNumb.includes("88888") ||
      phoneNumb.includes("99999") ||
      phoneNumb.includes("00000")
    ) {
      flag = true
      return flag
    } else {
      flag = false
      return flag
    }
  }

  function checkPhoneFormat() {
    let reg = /^[2-9][0-9]{7}$/gm
    if (reg.test(phoneNumb) && phoneNumb.length === 8) {
      return true
    } else {
      return false
    }
  }

  /******end vaildation */

  /*************OTP*********** */
  const requestOTP = async (e) => {
    e.preventDefault()
    setFormState((prevState) => ({ ...prevState, sendingSMS: true }))
    if (phoneNumb.length >= 8) {
      setFormState((prevState) => ({ ...prevState, disableSendCode: true })) // 禁用按钮

      generateRecaptha()
      let appVerifier = window.recaptchaVerifier
      signInWithPhoneNumber(auth, "+852" + phoneNumb, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult
          sentCode()
          setFormState((prevState) => ({
            ...prevState,
            disableSendCode: false,
          })) // 启用按钮
        })
        .catch((error) => {
          setFormState((prevState) => ({ ...prevState, showRecaptcha: false }))
          if (error.message.includes("too-many-requests")) {
            setFormState((prevState) => ({
              ...prevState,
              smserror: "抱歉!嘗試次數太多，請稍後再試。",
            }))
            failsendCode()
          } else if (error.message.includes("INVALID_CODE")) {
            setFormState((prevState) => ({
              ...prevState,
              smserror: "驗證碼錯誤，請重新輸入",
            }))
            failsendCode()
          } else if (error.message.includes("TOO_LONG")) {
            setFormState((prevState) => ({
              ...prevState,
              smserror: "無效電話號碼，請重新輸入",
            }))
            failsendCode()
          }
          failsendCode()
        })
    } else {
      return setFormState((prevState) => ({
        ...prevState,
        smserror: "請輸入正確號碼",
      }))
    }
  }

  const verifyOTP = () => {
    if (OTP.length !== 6)
      return setFormState((prevState) => ({
        ...prevState,
        smserror: "請輸入6位數驗證碼",
      }))
    setFormState((prevState) => ({
      ...prevState,
      showSpin: true,
    }))
    const otp = OTP
    let confirmationResult = window.confirmationResult
    confirmationResult
      .confirm(otp)
      .then((result) => {
        verified() //END of verification
        setFormState((prevState) => ({ ...prevState, showSpin: false }))
      })
      .catch((error) => {
        setFormState((prevState) => ({ ...prevState, showSpin: false }))
        if (error.message.includes("invalid")) {
          setFormState((prevState) => ({
            ...prevState,
            smserror: "驗證碼錯誤，請重新輸入",
          }))
        } else if (error.message.includes("expired")) {
          setFormState((prevState) => ({
            ...prevState,
            smserror: "驗證碼過期",
          }))
        }
        notVerified()
      })
  }

  /***************end OTP**********************/
  useEffect(() => {
    setPhone(phoneNumb)
  }, [phoneNumb])

  useEffect(() => {
    if (isSuccess) onSuccess(true)
    else onSuccess(false)
  }, [isSuccess])

  return (
    <Box>
      <TextField
        type="text"
        id="phoneNumb"
        label="手機號碼"
        aria-describedby="phoneHelpBlock"
        value={phoneNumb}
        disabled={isSuccess || sendingSMS}
        onChange={(e) =>
          setFormState((prevState) => ({
            ...prevState,
            phoneNumb: e.target.value,
          }))
        }
        fullWidth
        variant="outlined"
        margin="normal"
      />
      <FormHelperText id="phoneHelpBlock" className="text-muted ps-1">
        請提供有效流動電話號碼，以便接收密碼短訊
      </FormHelperText>
      {!phoneVerified && phoneNumb.length > 8 && (
        <Alert severity="error" className="alert-label alertcolor">
          請輸入正確號碼
        </Alert>
      )}

      {expandForm && (
        <>
          <TextField
            type="text"
            placeholder="SMS驗證碼"
            onChange={(e) => {
              setFormState((prevState) => ({
                ...prevState,
                OTP: e.target.value,
              }))
            }}
            disabled={isSuccess || sendingOTP}
            fullWidth
            variant="outlined"
          />
          {!showSpin && !redo && !isSuccess ? (
            <Button
              className={styles.customButton}
              variant="contained"
              color="primary"
              onClick={verifyOTP}
            >
              確認
            </Button>
          ) : (
            <Spinner show={showSpin} accessibilityLabel="spinner" />
          )}
        </>
      )}

      {isTesting && (
        <Button
          className={styles.customButton}
          variant="contained"
          color="primary"
          onClick={verified}
        >
          直接登入
        </Button>
      )}

      {!expandForm && showsendBtn && (
        <Button
          className={styles.customButton}
          variant="contained"
          color="primary"
          onClick={requestOTP}
          disabled={disableSendCode}
        >
          {disableSendCode ? "發送中..." : "送出驗證碼"}
        </Button>
      )}
      {showRecaptcha && !redo && (
        <div
          style={{ zIndex: 999 }}
          id="recaptcha-container"
          ref={recaptchaContainerRef}
        ></div>
      )}
      <Box className="d-flex flex-column">
        {redo && (
          <Button
            className={styles.customButton}
            variant="contained"
            onClick={restart}
          >
            重新驗證
          </Button>
        )}
        {isSentCode && !redo && !isSuccess && (
          <p className="color6 ms-2">驗證碼已寄出</p>
        )}
      </Box>
      {smserror && (
        <Alert
          severity="error"
          className="mt-2"
          style={{ width: "fit-content" }}
        >
          {smserror}
        </Alert>
      )}
      {error && (
        <Alert
          severity="error"
          className="mt-2"
          style={{ width: "fit-content" }}
        >
          {error}
        </Alert>
      )}
      {isSuccess && (
        <div className="color6">
          <span className="succes">✓</span> 電話號碼驗證成功
        </div>
      )}
    </Box>
  )
}

export default PhoneSMSVerification
